<template>
  <div class="header">
    <div class="header__url">
      <div class="me-1">
        <VIcon
          name="lock"
          width="16"
          height="16"
          class="header__url-icon me-2"
        />
        {{ $t('URL_VERIFICATION') }}:
      </div>
      <div>
        <span class="text-success">{{ protocol }}//</span>{{ url }}
      </div>
    </div>
    <MerchantLogo
      v-if="transactionTemplate && $route.meta.hasLogo"
      :logo-key="$route.params.key.toLowerCase()"
      :logo-url="transactionTemplate.additional_data?.logo_url"
    />
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { VIcon } from '@swapin/ui/Icon';

import { useTransactionStore } from '@/stores/transactions';

import MerchantLogo from './components/MerchantLogo.vue';

export default {
  components: {
    VIcon,
    MerchantLogo,
  },

  computed: {
    ...mapState(useTransactionStore, [
      'transactionTemplate',
    ]),

    protocol() {
      return window.location.protocol;
    },

    url() {
      const { host } = window.location;
      const { key } = this.$route.params;

      return key ? `${host}/${key}` : host;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__url {
    display: flex;
    flex-wrap: wrap;
    place-content: center center;
    padding: 0.5rem 1rem;
    font-size: $font-size-xs;
    background-color: $purple-100;

    &-icon {
      color: var(--bs-success);
    }
  }
}
</style>
