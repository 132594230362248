import { defineStore } from 'pinia';
import {
  getAnonTransaction,
  getTransactionTemplate,
  createTransactionByTemplate,
  confirmTransaction,
  cancelTransaction,
} from '@swapin-hub/api/transactions';

const TRANSACTION_STATUS = {
  NEW: 'N',
  INCOMPLETE: 'I',
  INPROGRESS: 'U',
  PROCESSING: 'P',
  COMPLETED_C: 'C',
  COMPLETED_S: 'S',
  RETURNED: 'R',
  CANCELLED: 'X',
  ARCHIVED: 'Z',
};
const RECEIVED_STATUSES = new Set(['E', 'F', 'G']);
const COMPLETED_STATUSES = new Set([
  TRANSACTION_STATUS.PROCESSING,
  TRANSACTION_STATUS.COMPLETED_S,
  TRANSACTION_STATUS.COMPLETED_C,
  TRANSACTION_STATUS.ARCHIVED,
]);
const TRANSACTION_FINAL_STATUSES = new Set([
  TRANSACTION_STATUS.CANCELLED,
  TRANSACTION_STATUS.ARCHIVED,
  TRANSACTION_STATUS.RETURNED,
]);
let statusPingIntervalId = 0;

export const useTransactionStore = defineStore('transactions', {
  state: () => ({
    transaction: null,
    transactionTemplate: null,
  }),

  actions: {
    initNewPayment(key) {
      this.transaction = null;

      if (!this.transactionTemplate) {
        this.getTransactionTemplate(key);
      }
    },

    async getTransaction(id) {
      const data = await getAnonTransaction(id);

      if (data.invoice) {
        this.transaction = data.invoice;
      }
    },

    async getTransactionTemplate(key) {
      const data = await getTransactionTemplate(key);

      if (data.template) {
        this.transactionTemplate = data.template;
      }
    },

    async createTransaction(formData) {
      const data = await createTransactionByTemplate(formData);

      if (data.invoice) {
        this.transaction = data.invoice;
      }

      return data;
    },

    async confirmTransaction(params) {
      const { user_confirmed, transaction_key } = this.transaction;

      if (!user_confirmed) {
        const data = await confirmTransaction(transaction_key, params);

        if (data.invoice) {
          this.transaction = data.invoice;
        }
      }
    },

    async cancelTransaction() {
      const data = await cancelTransaction(this.transaction.transaction_key);

      if (data.invoice) {
        this.transaction = data.invoice;
      }

      return data;
    },

    initStatusPing() {
      if (!this.transaction) {
        return;
      }

      const { status, transaction_key } = this.transaction;

      if (!TRANSACTION_FINAL_STATUSES.has(status)) {
        this.startStatusPing(transaction_key);
      }
    },

    startStatusPing(id) {
      this.stopStatusPing();

      statusPingIntervalId = setInterval(() => {
        getAnonTransaction(id).then((data) => {
          if (data.invoice) {
            this.transaction = data.invoice;

            if (TRANSACTION_FINAL_STATUSES.has(data.invoice.status)) {
              this.stopStatusPing();
            }
          }
        });
      }, 60_000);
    },

    stopStatusPing() {
      if (statusPingIntervalId) {
        clearInterval(statusPingIntervalId);
      }
    },
  },

  getters: {
    isNew(state) {
      return state.transaction?.status === TRANSACTION_STATUS.NEW;
    },

    isIncomplete(state) {
      return state.transaction?.status === TRANSACTION_STATUS.INCOMPLETE;
    },

    isInProgress(state) {
      return state.transaction?.status === TRANSACTION_STATUS.INPROGRESS;
    },

    isReceived(state) {
      return RECEIVED_STATUSES.has(state.transaction?.status);
    },

    isCompleted(state) {
      return COMPLETED_STATUSES.has(state.transaction?.status);
    },

    isCancelled(state) {
      return state.transaction?.status === TRANSACTION_STATUS.CANCELLED;
    },
  },
});
