import './assets/sass/main.scss';
import '@swapin/ui/dist/style.css';
import '@swapin/web3/dist/style.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { VueQueryPlugin } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import { Web3Plugin } from '@swapin/web3';
import { VTooltip } from '@swapin/ui/Tooltip';
import { createModal } from '@swapin/ui/Modal';
import i18n from '@swapin-hub/i18n';

import App from './App.vue';
import router from './router';

const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://982367c940d94de587cd7159ee7a7a8a@o1262827.ingest.sentry.io/6580961',
    release: __APP_VERSION__,
    environment: import.meta.env.VITE_ENV_DEV ? 'development' : 'production',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    tracesSampleRate: 1,
    tracePropagationTargets: [
      'collector.piix.eu',
      'pre-secure.swapin.com',
      'secure.swapin.com',
    ],
  });
}

app.use(VueQueryPlugin);
app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(createModal());
app.use(Web3Plugin);

app.directive('tooltip', VTooltip);

app.mount('#app');
