<template>
  <div class="h-100 d-flex flex-column">
    <TheHeader />
    <div class="d-flex flex-grow-1">
      <RouterView />
    </div>
    <TheFooter />
  </div>
  <ModalsContainer />
</template>

<script>
import { RouterView } from 'vue-router';
import { ModalsContainer } from '@swapin/ui/Modal';

import TheFooter from './views/Footer/TheFooter.vue';
import TheHeader from './views/Header/TheHeader.vue';

export default {
  components: {
    TheHeader,
    TheFooter,
    RouterView,
    ModalsContainer,
  },
};
</script>
