<template>
  <div class="footer">
    <div class="container">
      <hr class="footer__divider mt-5 mb-4">
      <div class="row">
        <div class="col-md-3">
          <div class="footer__powered-by">
            {{ $t('POWERED_BY') }}
            <img
              src="@images/logo.svg"
              height="32"
              class="mt-2"
            >
          </div>
        </div>
        <div class="col-md-5">
          <div>
            {{ $t('OPERATING_LICENSE') }}
            <a
              href="https://mtr.ttja.ee/taotluse_tulemus/541689"
              target="_blank"
            >FVT000088</a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="footer__license-logos">
            <div class="mb-2">
              {{ $t('LICENSES_REGISTERED_AT') }}:
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <img
                src="@images/logo-fiu.png"
                height="55"
                class="mb-2 me-2"
              >
              <img
                src="@images/logo-eu.png"
                height="36"
                class="mb-2"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  padding-bottom: 2rem;
  font-size: $font-size-sm;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  &__divider {
    border-top-color: var(--bs-gray-300);
    opacity: 1;
  }

  &__powered-by {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      align-items: center;
      margin-bottom: 2rem;
    }
  }

  &__license-logos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      align-items: center;
      margin-top: 2rem;
    }
  }
}
</style>
