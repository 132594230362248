<template>
  <div class="merchant-header mb-4">
    <img
      v-if="merchantLogoNotFound"
      src="@images/logo.svg"
      height="80"
      class="merchant-header__logo"
    >
    <img
      v-else-if="merchantLogoUrl"
      :src="merchantLogoUrl"
      class="merchant-header__logo"
      @error="merchantLogoNotFound = true"
    >
  </div>
</template>

<script>
export default {
  props: {
    logoKey: {
      type: String,
      required: true,
    },
    logoUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      merchantLogoUrl: '',
      merchantLogoNotFound: false,
    };
  },

  created() {
    this.merchantLogoUrl = this.logoUrl || `https://cdn.swapin.com/cc/${this.logoKey}.png`;
  },
};
</script>

<style lang="scss" scoped>
.merchant-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-color: var(--bs-white);

  &__logo {
    max-width: 75vw;
    max-height: 80px;
    object-fit: contain;
  }
}
</style>
