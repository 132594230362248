import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
  routes: [
    {
      path: '/:key',
      name: 'transaction',
      component: () => import('@/views/Transaction/TransactionPage.vue'),
      meta: {
        hasLogo: true,
      },
    },
    {
      path: '/:key/:id',
      name: 'transaction-details',
      component: () => import('@/views/Transaction/TransactionDetailsPage.vue'),
      meta: {
        hasLogo: true,
      },
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/Errors/NotFoundPage.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '404',
    },
  ],
});

export default router;
